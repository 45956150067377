@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

h1 {
  @apply text-2xl font-bold;
}
h2 {
  @apply text-xl font-bold;
}
h3 {
  @apply text-lg font-bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.articleC blockquote,
.articleC dl,
.articleC dd,
.articleC h1,
.articleC h2,
.articleC h3,
.articleC h4,
.articleC h5,
.articleC h6,
.articleC hr,
.articleC figure,
.articleC p,
.articleC pre {
  margin: revert !important;
}

.articleC ol,
.articleC ul,
.articleC menu {
  margin: revert !important;
  padding: revert !important;
  list-style: revert !important;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
